module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<footer class="b-footer-info">\r\n	';
 if (this.copyright) { ;
__p += '\r\n        <div class="b-footer-info__bottom-block-container">\r\n            ' +
((__t = ( this.copyright )) == null ? '' : __t) +
'\r\n        </div>\r\n	';
 } ;
__p += '\r\n	<div class="b-footer-info__copyright">\r\n		<!-- <div>' +
((__t = ( L10N.getUnescaped('footer.text') )) == null ? '' : __t) +
'</div> -->\r\n		<div>' +
((__t = ( L10N.get("footer.allRightsReserved").replace("%s", new Date().getFullYear()) )) == null ? '' : __t) +
'</div>\r\n	</div>\r\n</footer>';

}
return __p
}