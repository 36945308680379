module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="p-login__container">\r\n	<div class="p-login ' +
((__t = ( STATE.checkSiteType('B2C') ? 'is-b2c' : '' )) == null ? '' : __t) +
'">\r\n		<div class="p-login__wrapper js-login-wrapper">\r\n			';
 if (!STATE.checkSiteType('B2C')) { ;
__p += '\r\n				<div class="p-login__header">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Logo()\'></span>\r\n					<div class="p-login__header-wrapper">\r\n						<!-- <span class="js-widget"  onclick=\'return new Widgets.Title()\'></span> -->\r\n						<span class="js-widget"  onclick=\'return new Widgets.Language()\'></span>\r\n					</div>\r\n				</div>\r\n			';
 } ;
__p += '\r\n			<div class="p-login__form">\r\n				<span class="js-widget"  onclick=\'return new Widgets.Title()\'></span>\r\n			</div>\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}