/* eslint-disable banned-modules */
/* eslint-disable camelcase */
'use strict';

import './style.less';
import $ from 'jquery';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import SelectWidget from '@/widgets/b-select/';
import CheckBoxWidget from '@/widgets/b-checkbox/';

import template from './default.ejs';
import templateP from './map_P.ejs';
import templateP_44P from './map_P_44P.ejs';
import templateK from './map_K.ejs';
import templateK36 from './map_K36.ejs';
import templateK71 from './map_K71.ejs';
import templateK72 from './map_K72.ejs';
import templateK35 from './map_K35.ejs';
import templateK80 from './map_K80.ejs';
import templateK82 from './map_K82.ejs';
import templateK2 from './map_K2.ejs';
import templateL from './map_L.ejs';
import templateL2_TF from './map_L2_TF.ejs';
import templateL2 from './map_L2.ejs';
import templateL3 from './map_L3.ejs';
import templateM6 from './map_M6.ejs';
import templateM8 from './map_M8.ejs';
import templateM12 from './map_M12.ejs';
import templateSAPSAN_1 from './map_SAPSAN_1.ejs';
import templateSAPSAN_2 from './map_SAPSAN_2.ejs';
import templateSAPSAN_3 from './map_SAPSAN_3.ejs';
import templateSAPSAN_4 from './map_SAPSAN_4.ejs';
import templateSAPSAN_5_15 from './map_SAPSAN_5_15.ejs';
import templateSAPSAN_6 from './map_SAPSAN_6.ejs';
import templateSAPSAN_7 from './map_SAPSAN_7.ejs';
import templateSAPSAN_8 from './map_SAPSAN_8.ejs';
import templateSAPSAN_9 from './map_SAPSAN_9.ejs';
import templateSAPSAN_10 from './map_SAPSAN_10.ejs';
import templateSAPSAN_54C from './map_SAPSAN_54C.ejs';
import templateSAPSAN_55C from './map_SAPSAN_55C.ejs';
import templateSTRIZH_2 from './map_STRIZH_2.ejs';
import templateSTRIZH_1 from './map_STRIZH_1.ejs';
import templateSTRIZH_98C from './map_STRIZH_98C.ejs';
import templateSTRIZH_30L from './map_STRIZH_30L.ejs';
import templateSTRIZH_32L from './map_STRIZH_32L.ejs';
import templateSTRIZH_33L from './map_STRIZH_33L.ejs';
import templateSTRIZH_LUX from './map_STRIZH_LUX.ejs';
import templateSTRIZH_MB_K_CLASS_2 from './map_STRIZH_MB_K_CLASS_2.ejs';
import templateSTRIZH_MB from './map_STRIZH_MB.ejs';
import templateSTRIZH_MB_I from './map_STRIZH_MB_I.ejs';
import templateSTRIZH_MB_L_CLASS_1 from './map_STRIZH_MB_L_CLASS_1.ejs';
import templateS_TF_CLASS_1 from './map_S_TF_CLASS_1.ejs';
import templateS_TF_CLASS_2 from './map_S_TF_CLASS_2.ejs';
import templateLASTOCHKA_STD_16 from './map_LASTOCHKA_STD_16.ejs';
import templateLASTOCHKA_STD_234789 from './map_LASTOCHKA_STD_234789.ejs';
import templateLASTOCHKA_STD_510 from './map_LASTOCHKA_STD_510.ejs';
import templateLASTOCHKA_PREM_1 from './map_LASTOCHKA_PREM_1.ejs';
import templateLASTOCHKA_PREM_2 from './map_LASTOCHKA_PREM_2.ejs';
import templateLASTOCHKA_PREM_34 from './map_LASTOCHKA_PREM_34.ejs';
import templateLASTOCHKA_PREM_5 from './map_LASTOCHKA_PREM_5.ejs';
import templateNEVSKIIEXPRES_K_23467891011 from './map_NEVSKIIEXPRES_K_23467891011.ejs';
import templateNEVSKIIEXPRES_K_5 from './map_NEVSKIIEXPRES_K_5.ejs';
import templateNEVSKIIEXPRES_S_112 from './map_NEVSKIIEXPRES_S_112.ejs';
import templatePOLONEZ_K from './map_POLONEZ_K.ejs';
import templatePOLONEZ_010ZH_K from './map_POLONEZ_010ZH_K.ejs';
import templatePOLONEZ_SV from './map_POLONEZ_SV.ejs';
import templateALLEGRO_1_8 from './map_ALLEGRO_1_8.ejs';
import templateALLEGRO_2_9 from './map_ALLEGRO_2_9.ejs';
import templateALLEGRO_4_11 from './map_ALLEGRO_4_11.ejs';
import templateALLEGRO_5_12 from './map_ALLEGRO_5_12.ejs';
import templateALLEGRO_6_13 from './map_ALLEGRO_6_13.ejs';
import templateALLEGRO_7_14 from './map_ALLEGRO_7_14.ejs';
import templateL_WITH_UPPER from './map_L_WITH_UPPER.ejs';
import templateSTANDARD_60_741A from './map_STANDARD_60_741A.ejs';
import templateSTANDARD_62_258A from './map_STANDARD_62_258A.ejs';
import templateS_60 from './map_S_60.ejs';
import templateS_68 from './map_S_68.ejs';
import templateS_80 from './map_S_80_108.ejs';
import templateGRAND_L_WAGON_7 from './map_GRAND_L_WAGON_7.ejs';
import templateMOW_BRK_42 from './map_MOW_BRK_42.ejs';
import templateMOW_BRK_68 from './map_MOW_BRK_68.ejs';
import templateNEVSKII_EXPRES_K_13_CAR from './map_NEVSKII_EXPRES_K_13_CAR.ejs';
import templateMOW_GOM_055_K_40 from './map_MOW_GOM_055_K_40.ejs';
import templateMOW_PARIS_023_K from './map_MOW_PARIS_023_K.ejs';
import templateMOW_PARIS_023_SV from './map_MOW_PARIS_023_SV.ejs';
import templateMOW_PARIS_023_LUX from './map_MOW_PARIS_023_LUX.ejs';
import templateNICE_017_COUPE from './map_NICE_017_COUPE.ejs';
import templateNICE_017_SV from './map_NICE_017_SV.ejs';
import templateLASTOCHKA_STD_6_10 from './map_LASTOCHKA_STD_6_10.ejs';
import templateLASTOCHKA_STD_7_9 from './map_LASTOCHKA_STD_7_9.ejs';
import templateKZH_1_2_V2 from './map_KZH_1_2_V2.ejs';
import templateKZH_3_6_V1 from './map_KZH_3_6_V1.ejs';
import templateKZH_O_V1 from './map_KZH_O_V1.ejs';
import templateKZH_TALGO_BSN1_12 from './map_KZH_TALGO_BSN1_12.ejs';
import templateKZH_TALGO_GRAND_PMR1 from './map_KZH_TALGO_GRAND_PMR1.ejs';
import templateKZH_TALGO_SID1 from './map_KZH_TALGO_SID1.ejs';
import templateKZH_TALGO_SID2 from './map_KZH_TALGO_SID2.ejs';
import templateKZH_TALGO_TUR1_18 from './map_KZH_TALGO_TUR1_18.ejs';
import templateKZH_TALGO_TUR2_20 from './map_KZH_TALGO_TUR2_20.ejs';
import templateLASTOCHKA_723M_1 from './map_LASTOCHKA_723M_1.ejs';
import templateLASTOCHKA_723M_2 from './map_LASTOCHKA_723M_2.ejs';
import templateLASTOCHKA_723M_3 from './map_LASTOCHKA_723M_3.ejs';
import templateLASTOCHKA_723M_4 from './map_LASTOCHKA_723M_4.ejs';
import templateLASTOCHKA_723M_5 from './map_LASTOCHKA_723M_5.ejs';
import templateLASTOCHKA_715YA_1 from './map_LASTOCHKA_715YA_1.ejs';
import templateLASTOCHKA_715YA_2 from './map_LASTOCHKA_715YA_2.ejs';
import templateLASTOCHKA_715YA_3 from './map_LASTOCHKA_715YA_3.ejs';
import templateLASTOCHKA_715YA_4 from './map_LASTOCHKA_715YA_4.ejs';
import templateLASTOCHKA_715YA_5 from './map_LASTOCHKA_715YA_5.ejs';
import templateLASTOCHKA_37C from './map_LASTOCHKA_37C.ejs';
import templateLASTOCHKA_41C from './map_LASTOCHKA_41C.ejs';
import templateLASTOCHKA_45C from './map_LASTOCHKA_45C.ejs';
import templateLASTOCHKA_87C from './map_LASTOCHKA_87C.ejs';
import templateLASTOCHKA_89C from './map_LASTOCHKA_89C.ejs';
import templateLASTOCHKA_90C from './map_LASTOCHKA_90C.ejs';
import templateNUMBER_105YA_S_42 from './map_NUMBER_105YA_S_42.ejs';
import templateNUMBER_105YA_S_68 from './map_NUMBER_105YA_S_68.ejs';
import templateGRAND_M_1N from './map_GRAND_M_1N.ejs';
import templateGRAND_M_1M from './map_GRAND_M_1M.ejs';
import templateGRAND_L_1L from './map_GRAND_L_1L.ejs';
import templateGRAND_L_1E from './map_GRAND_L_1E.ejs';
import templateL2_20 from './map_L2_20.ejs';
import templateL27 from './map_L27.ejs';
import templateL31 from './map_L31.ejs';
import templateC97 from './map_C97.ejs';
import templateBRAND_76L from './map_BRAND_76L.ejs';
import templateIVAN_PARISTY_2 from './map_IVAN_PARISTY_2.ejs';
import templateEMPTY_MAP from './map_EMPTY_MAP.ejs';

import templateARKTIKA_K from './map_ARKTIKA_K.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .place:not(.readonly)': 'selectPlace',
	},

	numbers: null,

	initialize(options) {
		const DEBUG_MODE = false;

		this.numbers = [];
		this.inmobMapStyleTagContent = {};
		this.parent = options.parent;
		this.carriage = options.carriage;
		this.carriageCardId = this.carriage.carriageCardId;
		this.mapKey = this.carriageCardId;

		/* map templates */
		this.templates = {
			UNIDENTIFIED: template,
			P: templateP,
			P_44P: templateP_44P,
			K: templateK,
			K2: templateK2,
			K35: templateK35,
			K36: templateK36,
			K71: templateK71,
			K72: templateK72,
			K80: templateK80,
			K82: templateK82,
			L: templateL,
			L2: templateL2,
			L27: templateL27,
			L31: templateL31,
			L2_TF: templateL2_TF,
			L3: templateL3,
			SAPSAN_1: templateSAPSAN_1,
			SAPSAN_2: templateSAPSAN_2,
			SAPSAN_3: templateSAPSAN_3,
			SAPSAN_4: templateSAPSAN_4,
			SAPSAN_5_15: templateSAPSAN_5_15,
			SAPSAN_6: templateSAPSAN_6,
			SAPSAN_7: templateSAPSAN_7,
			SAPSAN_8: templateSAPSAN_8,
			SAPSAN_9: templateSAPSAN_9,
			SAPSAN_10: templateSAPSAN_10,
			SAPSAN_54C: templateSAPSAN_54C,
			SAPSAN_55C: templateSAPSAN_55C,
			STRIZH_2: templateSTRIZH_2,
			STRIZH_1: templateSTRIZH_1,
			STRIZH_98C: templateSTRIZH_98C,
			STRIZH_LUX: templateSTRIZH_LUX,
			STRIZH_30L: templateSTRIZH_30L,
			STRIZH_32L: templateSTRIZH_32L,
			STRIZH_33L: templateSTRIZH_33L,
			M6: templateM6,
			M8: templateM8,
			M12: templateM12,
			S_TF_CLASS_1: templateS_TF_CLASS_1,
			S_TF_CLASS_2: templateS_TF_CLASS_2,
			STRIZH_MB_K_CLASS_2: templateSTRIZH_MB_K_CLASS_2,
			STRIZH_MB: templateSTRIZH_MB,
			STRIZH_MB_I: templateSTRIZH_MB_I,
			STRIZH_MB_L_CLASS_1: templateSTRIZH_MB_L_CLASS_1,
			LASTOCHKA_STD_16: templateLASTOCHKA_STD_16,
			LASTOCHKA_STD_234789: templateLASTOCHKA_STD_234789,
			LASTOCHKA_STD_510: templateLASTOCHKA_STD_510,
			LASTOCHKA_PREM_1: templateLASTOCHKA_PREM_1,
			LASTOCHKA_PREM_2: templateLASTOCHKA_PREM_2,
			LASTOCHKA_PREM_34: templateLASTOCHKA_PREM_34,
			LASTOCHKA_PREM_5: templateLASTOCHKA_PREM_5,
			NEVSKIIEXPRES_K_23467891011: templateNEVSKIIEXPRES_K_23467891011,
			NEVSKIIEXPRES_K_5: templateNEVSKIIEXPRES_K_5,
			NEVSKIIEXPRES_S_112: templateNEVSKIIEXPRES_S_112,
			POLONEZ_K: templatePOLONEZ_K,
			POLONEZ_010ZH_K: templatePOLONEZ_010ZH_K,
			POLONEZ_SV: templatePOLONEZ_SV,
			ALLEGRO_1_8: templateALLEGRO_1_8,
			ALLEGRO_2_9: templateALLEGRO_2_9,
			ALLEGRO_4_11: templateALLEGRO_4_11,
			ALLEGRO_5_12: templateALLEGRO_5_12,
			ALLEGRO_6_13: templateALLEGRO_6_13,
			ALLEGRO_7_14: templateALLEGRO_7_14,
			L_WITH_UPPER: templateL_WITH_UPPER,
			STANDARD_60_741A: templateSTANDARD_60_741A,
			STANDARD_62_258A: templateSTANDARD_62_258A,
			BRAND_76L: templateBRAND_76L,
			S_60: templateS_60,
			S_68: templateS_68,
			S_80: templateS_80,
			GRAND_L_WAGON_7: templateGRAND_L_WAGON_7,
			MOW_BRK_42: templateMOW_BRK_42,
			MOW_BRK_68: templateMOW_BRK_68,
			NEVSKII_EXPRES_K_13_CAR: templateNEVSKII_EXPRES_K_13_CAR,
			MOW_GOM_055_K_40: templateMOW_GOM_055_K_40,
			MOW_PARIS_023_K: templateMOW_PARIS_023_K,
			MOW_PARIS_023_SV: templateMOW_PARIS_023_SV,
			MOW_PARIS_023_LUX: templateMOW_PARIS_023_LUX,
			NICE_017_COUPE: templateNICE_017_COUPE,
			NICE_017_SV: templateNICE_017_SV,
			LASTOCHKA_STD_6_10: templateLASTOCHKA_STD_6_10,
			LASTOCHKA_STD_7_9: templateLASTOCHKA_STD_7_9,
			KZH_1_2_V2: templateKZH_1_2_V2,
			KZH_3_6_V1: templateKZH_3_6_V1,
			KZH_O_V1: templateKZH_O_V1,
			KZH_TALGO_BSN1_12: templateKZH_TALGO_BSN1_12,
			KZH_TALGO_GRAND_PMR1: templateKZH_TALGO_GRAND_PMR1,
			KZH_TALGO_SID1: templateKZH_TALGO_SID1,
			KZH_TALGO_SID2: templateKZH_TALGO_SID2,
			KZH_TALGO_TUR1_18: templateKZH_TALGO_TUR1_18,
			KZH_TALGO_TUR2_20: templateKZH_TALGO_TUR2_20,
			LASTOCHKA_723M_1: templateLASTOCHKA_723M_1,
			LASTOCHKA_723M_2: templateLASTOCHKA_723M_2,
			LASTOCHKA_723M_3: templateLASTOCHKA_723M_3,
			LASTOCHKA_723M_4: templateLASTOCHKA_723M_4,
			LASTOCHKA_723M_5: templateLASTOCHKA_723M_5,
			LASTOCHKA_715YA_1: templateLASTOCHKA_715YA_1,
			LASTOCHKA_715YA_2: templateLASTOCHKA_715YA_2,
			LASTOCHKA_715YA_3: templateLASTOCHKA_715YA_3,
			LASTOCHKA_715YA_4: templateLASTOCHKA_715YA_4,
			LASTOCHKA_715YA_5: templateLASTOCHKA_715YA_5,
			LASTOCHKA_37C: templateLASTOCHKA_37C,
			LASTOCHKA_41C: templateLASTOCHKA_41C,
			LASTOCHKA_45C: templateLASTOCHKA_45C,
			LASTOCHKA_87C: templateLASTOCHKA_87C,
			LASTOCHKA_89C: templateLASTOCHKA_89C,
			LASTOCHKA_90C: templateLASTOCHKA_90C,
			NUMBER_105YA_S_42: templateNUMBER_105YA_S_42,
			NUMBER_105YA_S_68: templateNUMBER_105YA_S_68,
			ARKTIKA_K: templateARKTIKA_K,
			GRAND_M_1N: templateGRAND_M_1N,
			GRAND_M_1M: templateGRAND_M_1M,
			GRAND_L_1L: templateGRAND_L_1L,
			GRAND_L_1E: templateGRAND_L_1E,
			L2_20: templateL2_20,
			IVAN_PARISTY_2: templateIVAN_PARISTY_2,
			EMPTY_MAP: templateEMPTY_MAP,
			C97: templateC97,
		};

		if (this.options.inMobMap != null) {
			this.mapKey = this.options.providerCarSchemeId;
			this.convertInmobMapToCompatible();
		}

		if (this.mapKey in this.templates) {
			this.template = this.templates[this.mapKey];
		}
		/* EOF map templates */

		if (this.options.previewOnly !== true) {
			this.seatsAvailable = this.carriage.seatsAvailable.reduce((map, obj) => {
				map[parseInt(obj.number, 10)] = obj;
				return map;
			}, {});

			/* seats limit */
			this.seatsLimit = 4;
			if (this.carriage.seatsGroupSizeRestricted) {
				this.seatsLimit = this.carriage.seatsGroupSize;
			}
			/* round trip case*/
			if (options.seatsAmount != null && options.seatsAmount > 0) {
				this.seatsLimit = options.seatsAmount;
			}
			/* EOF seats limit */

			this.render();

			this.$el.addClass(`map_${this.carriage._id}`);
			this.$el.attr('data-id', this.carriage._id);
			this.$el.hide();
			this.updateMap();

			// DEBUG MODE
			if (DEBUG_MODE === true) {
				const $wrapper = $('<div class="debug-container"></div>');
				const $container = $('<div class="l-grid-container"></div>');
				const $textarea = $('<textarea readonly></textarea>').hide();

				const debugModel = new BaseModel();
				const select = new SelectWidget({
					bindingProperty: 'mapId',
					label: L10N.get('trains.map'),
					values: SelectWidget.storeCollection(
						SelectWidget.dictionaryToCollection(_.map(_.sortBy(Object.keys(this.templates)), (el) => ({
							caption: el,
							uid: el,
						}))),
					),
				});
				const checkbox = new CheckBoxWidget({
					bindingProperty: 'fullNumbers',
					label: L10N.get('trains.allSeats'),
				});

				select.applyBinding(debugModel);
				checkbox.applyBinding(debugModel);

				this.$el.prepend($wrapper);
				$wrapper.append($container);

				$container.after($textarea);
				$container.append($('<div class="l-grid-layout-33"></div>').append(select.$el));
				$container.append($('<div class="l-grid-layout-33"></div>').append(checkbox.$el));
				$container
					.append($('<div class="l-grid-layout-33"></div>')
						.append($(`<button class="btn btn-dark js-check-seats">${L10N.get('trains.checkMap')}</button>`),
							$(`<button class="btn btn-dark js-check-seats-all">${L10N.get('trains.checkAllMaps')}</button>`)));

				this.$el.find('.js-check-seats-all').click(() => {
					$textarea.show().val('');
					const mapList = _.sortBy(Object.keys(this.templates));
					const checkMaps = (i = 0) => {
						const el = mapList[i];

						if (el != null) {
							debugModel.set('mapId', {
								caption: el,
								uid: el,
							});

							const deferred = $.Deferred();
							$container.find('.js-check-seats').trigger('click', [deferred]);
							deferred.done(() => {
								_.defer(() => {
									checkMaps(++i);
								});
							});
						}
					};
					checkMaps();
				});

				$container.find('.js-check-seats').click((e, deferred) => {
					let text = '';

					const hasCoupe = this.$el.find('rect[data-coupe]').length > 0;
					const hasPosition = this.$el.find('rect[data-position]').length > 0;

					const that = this;
					const regex = new RegExp('^\\d+$');
					$textarea.show();

					text += `CHECK MAP ID: ${debugModel.get('mapId').uid}\r\n`;
					this.$el.find('tspan')
						.filter(function onlyNumbers() { return regex.test($(this).text()); })
						.each(function checkSeats() {
							const $text = $(this).closest('text');
							const numberText = parseInt($text.data('number'), 10);

							const $rect = that.$el.find(`rect[data-number="${numberText}"]`);
							const numberRect = parseInt($rect.data('number'), 10);

							if ($rect.next('text')[0] == null && $rect.next('path').next('text')[0] == null && $text[0] == null) {
								return;
							}

							if ((numberText !== numberRect) || isNaN(numberText) || isNaN(numberRect)) {
								text += `rect[data-number="${numberRect}"] - rect number does not match text number\r\n`;
							}

							if (parseInt($text.find('tspan').text(), 10) !== numberRect) {
								text += `rect[data-number="${numberRect}"] - rect number does not match text label\r\n`;
							}

							if (that.$el.find(`rect[data-number="${numberRect}"]`).length > 1) {
								text += `rect[data-number="${numberRect}"] - duplicate seat number\r\n`;
							}

							if (hasCoupe && $rect.data('coupe') == null) {
								text += `rect[data-number="${numberRect}"] - missing data-coupe attribute\r\n`;
							}

							if (hasPosition && $rect.data('position') == null) {
								text += `rect[data-number="${numberRect}"] - missing data-position attribute\r\n`;
							}

							if (!$rect.hasClass('place-sold_out')) {
								$rect.trigger('click');
								if (!_.map(that.numbers, (el) => parseInt(el.number, 10)).includes(numberRect)) {
									text += `rect[data-number="${numberRect}"] - not working click\r\n`;
								}
								$rect.trigger('click');
							}
						});

					$textarea.val((i, content) => {
						return `${content}${text}Check map completed\r\n\r\n`;
					});

					$textarea.scrollTop($textarea[0].scrollHeight);
					if (deferred != null) {
						deferred.resolve();
					}
				});

				debugModel.set('mapId', {
					uid: this.carriageCardId,
					caption: this.carriageCardId,
				});

				this.listenTo(debugModel, 'change:mapId', (model, value) => {
					this.template = this.templates[value.uid];

					this.$el.find('.b-train__carriage-map, .b-train__carriage-map__car-number, svg').remove();
					this.render();
					this.delegateEvents();
					this.updateMap();
				});

				const savedseatsAvailable = _.extend({}, this.seatsAvailable);
				this.listenTo(debugModel, 'change:fullNumbers', (model, value) => {
					let maxSeat = null;

					if (value === true) {
						this.$el.find('rect[data-number]').each(function findxMaxNumber() {
							const $rect = $(this);
							const number = parseInt($rect.data('number'), 10);
							maxSeat = (number > maxSeat) ? number : maxSeat;
						});

						if (maxSeat != null) {
							this.seatsAvailable = {};
							for (let i = maxSeat; i >= 0; i--) {
								this.seatsAvailable[i] = {
									number: i,
								};
							}
						}
					} else {
						this.seatsAvailable = savedseatsAvailable;
					}

					this.$el.find('.b-train__carriage-map, .b-train__carriage-map__car-number, svg').remove();
					this.render();
					this.delegateEvents();
					this.updateMap();
				});
			}
		} else {
			this.render();
			this.$el.addClass(`map_${this.carriage._id}`);
			this.$el.attr('data-id', this.carriage._id);

			_.each(this.options.numbers, (n) => {
				const number = parseInt(n, 10);
				if (_.isNaN(number)) {
					return;
				}

				const place = this.$el.find(`[data-number="${number}"]`);
				place.addClass('place selected readonly');
			});
		}
	},

	updateMap() {
		this.numbers = [];
		let carNumberText = `${L10N.get('trains.carriage')} №${this.carriage.number}`;
		if (this.carriage.carPlaceType != null) {
			carNumberText += ` - ${this.carriage.carPlaceType.caption}`;
		}
		this.$el.find('.js-car-number').text(carNumberText);
		const seatsAvailable = this.seatsAvailable;
		const _this = this;
		this.$el.find('[data-number]').each(function updatePlaces() {
			const $rect = $(this);
			const number = parseInt($rect.data('number'), 10);
			const $text = _this.$el.find(`text[data-number="${number}"]`);
			const available = number in seatsAvailable;

			/* clear prev */
			$rect.add($text).removeClass('place')
				.removeClass('place-sold_out')
				.removeClass('selected');
			/* EOF clear prev */

			if (available && seatsAvailable[number].gender != null) {
				const genderUid = seatsAvailable[number].gender.uid;
				$rect.addClass('gender-place').addClass(genderUid);
				$text.addClass('gender-place').addClass(genderUid);
				$rect.data('gender', genderUid);
				$text.data('gender', genderUid);
			}

			if (available) {
				$rect.addClass('place');
				$text.addClass('place');

				if ($text.attr('data-number') == null) {
					$text.attr('data-number', number);
				}
			} else {
				$rect.addClass('place-sold_out');
				$text.addClass('place-sold_out');
			}
		});
	},

	updateAvailablePlaces(seat) {
		const t = this;
		const hideAvailable = false;

		let selectedGender = this.getSelectedGender();
		let placeGender = null;

		this.fillRange();

		this.numbers.forEach(n => {
			if (n.gender != null) {
				placeGender = n.gender;
			}
		});

		if (this.numbers.length === 0) {
			selectedGender = null;
			this.setSelectedGender(null);
		}

		if (!selectedGender && placeGender != null) {
			if (selectedGender === null) {
				selectedGender = placeGender;
				this.setSelectedGender(placeGender);
			}
		}

		const _this = this;
		this.$el.find('[data-number]').each(function updatePlaces() {
			const $rect = $(this);
			const number = parseInt($rect.data('number'), 10);
			const $text = _this.$el.find(`text[data-number="${number}"]`);
			const $place = $rect.add($text);
			const $placeGender = $rect.data('gender');

			const selected = t.numbers.findIndex((n) => {
				return parseInt(n.number, 10) === number;
			});

			if (selected > -1) {
				$place.addClass('selected');
			} else {
				$place.removeClass('selected');
			}

			if (hideAvailable) {
				// hide available not selected
				if (!$rect.hasClass('selected') && !$rect.hasClass('place-sold_out')) {
					$rect.removeClass('place').addClass('unavailable');
					$text.removeClass('place').addClass('unavailable');
				}
			} else {
				// show available not selected
				if (!$rect.hasClass('selected') && !$rect.hasClass('place-sold_out')) {
					$rect.addClass('place');
					$text.addClass('place');
				}
				// clean up gender classes
				if ($rect.hasClass('NOT_SELECTED')) {
					$place.removeClass('MALE');
					$place.removeClass('FEMALE');
					$place.removeClass('MIX');
				}

				$place.removeClass('unavailable');

				if (selectedGender != null && $rect.hasClass('gender-place')) {
					if ($placeGender !== selectedGender.uid && $placeGender !== 'NOT_SELECTED') {
						// remove selected unavailable
						if (selected > -1) {
							$place.removeClass('selected');
							t.numbers.splice(selected, 1);
						}
						$place.addClass('unavailable');
						// switch to selected gender
					} else if ($rect.data('gender') === 'NOT_SELECTED') {
						$place.addClass(selectedGender.uid);
					}
				}
			}
		});

		if (!_.isEmpty(seat) && seat.number) {
			const { carPlaceType } = _.find(this.carriage.carPlaceData, (c) => c.availableSeats.includes(seat.number)) || {};
			const selected = this.numbers.findIndex((n) => n.number === seat.number) > -1;

			if (carPlaceType == null) {
				return 'DEFAULT';
			}

			const $availableSeatsRect = this.$el.find(`.place[data-number]:not([data-number="${seat.number}"]):not(.place-sold_out)`);
			const $manipulatedSeatsRect = this.$el.find(`.place-manipulated[data-number]:not([data-number="${seat.number}"])`);

			const setStatusRects = ($rects, disabled = true) => {
				if ($rects instanceof $) {
					if (disabled === true) {
						$rects.removeClass('place').addClass('place-sold_out place-manipulated');
					} else {
						$rects.addClass('place').removeClass('place-sold_out place-manipulated');
					}
				}
			};

			if (selected === true) {
				if (carPlaceType.uid === 'MOTHER_AND_BABY') {
					setStatusRects($availableSeatsRect, true);

					return 'MOTHER_AND_BABY_SELECTED';
				}

				if (carPlaceType.uid === 'WITH_CHILD') {
					const { availableSeats } = _.find(this.carriage.carPlaceData, (c) => c.carPlaceType.uid === 'WITH_CHILD') || {};
					const selector = _.map(availableSeats, (number) => `:not([data-number="${number}"])`).join('');

					if (selector) {
						setStatusRects($availableSeatsRect.filter(`[data-number]${selector}`), true);
					}

					return 'WITH_CHILD_SELECTED';
				}

				// OTHERS SEATS
				const carriages = _.filter(this.carriage.carPlaceData, (c) => ['WITH_CHILD', 'MOTHER_AND_BABY'].includes(c.carPlaceType.uid));
				const notAvailableSeats = _.reduce(carriages, (acc, c) => acc.concat(c.availableSeats), []);
				const selector = _.map(notAvailableSeats, (number) => `[data-number="${number}"]`).join();

				if (selector) {
					setStatusRects($availableSeatsRect.filter(`[data-number]${selector}`), true);
				}
			}

			if (selected !== true && $manipulatedSeatsRect.length) {
				setStatusRects($manipulatedSeatsRect, false);
			}
		}

		if (this.carriage.seatsGroupSizeRestricted === true) {
			const $availableSeatsRect = this.$el.find(`.place[data-number]:not(.place-sold_out):not(.selected)`);
			const $manipulatedSeatsRect = this.$el.find(`.place-manipulated[data-number]`);

			if (this.carriage.seatsGroupSize === this.numbers.length) {
				$availableSeatsRect.removeClass('place').addClass('place-sold_out place-manipulated');
			} else {
				$manipulatedSeatsRect.addClass('place').removeClass('place-sold_out place-manipulated');
			}

			const passengerSum = _.reduce(_.values(this.options.passengersTypes), (sum, n) => (sum += n), 0);
			if (_.size(this.numbers) && passengerSum && this.carriage.seatsGroupSize < passengerSum) {
				return 'SEATS_GROUP_SIZE';
			}
		}

		return 'DEFAULT';
	},

	fillRange() {
		if (this.numbers.length > 1) {
			const numbersMap = this.numbers.map(n => parseInt(n.number, 10));
			const min = Math.min(...numbersMap);
			const max = Math.max(...numbersMap);
			const numbersRange = [];
			const selectedGender = this.getSelectedGender();
			for (let i = min; i <= max; i++) {
				if (i in this.seatsAvailable) {
					const seat = this.seatsAvailable[i];
					if (selectedGender != null && seat.gender != null) {
						if (seat.gender.uid === selectedGender.uid || seat.gender.uid === 'NOT_SELECTED') {
							numbersRange.push(seat);
						}
					} else {
						numbersRange.push(seat);
					}
				}
			}
			this.numbers = numbersRange;
		}
		this.numbers.forEach(n => {
			const number = this.$el.find(`[data-number="${n.number}"]`);
			const coupe = number.data('coupe') != null ? parseInt(number.data('coupe'), 10) : null;
			const position = number.data('position');
			if (coupe != null) {
				_.extend(n, {
					coupe,
				});
			}
			if (position != null) {
				_.extend(n, {
					position,
				});
			}
		});
	},

	selectPlace(e) {
		if (e) {
			e.preventDefault();
		}
		const $currentTarget = $(e.currentTarget);
		const number = parseInt($currentTarget.data('number'), 10);
		const coupe = parseInt($currentTarget.data('coupe'), 10);

		if ($currentTarget.hasClass('unavailable')) {
			return;
		}

		this._selectPlace(number, coupe);
	},

	_selectPlace(number, coupe) {
		const numberIndex = this.numbers.findIndex(n => parseInt(n.number, 10) === parseInt(number, 10));
		const coupeElements = this.$el.find(`rect[data-coupe="${coupe}"]`) || this.$el.find(`path[data-coupe="${coupe}"]`);
		const coupeSize = coupeElements.length;
		const coupeSeats = Array.from(coupeElements).map((el) => {
			const seatNumber = el.dataset ? this.seatsAvailable[el.dataset.number] : undefined;
			const type = el.dataset ? el.dataset.position : undefined;
			return {
				number: seatNumber,
				type,
			};
		}).filter((seats) => {
			return seats !== undefined && seats.number !== undefined;
		});
		const seat = {...this.seatsAvailable[number], coupeSeats, coupeSize};
		const extendNumbers = [];

		let carPlaceData = null;
		if (this.carriage.seatsGroupSizeRestricted === true) {
			carPlaceData = _.find(this.carriage.carPlaceData, (el) => el.availableSeats.includes(number.toString()));

			if (!_.isNaN(coupe) && coupe) {
				const dataCoupe = this.$el.find(`rect[data-coupe="${coupe}"]`) || this.$el.find(`path[data-coupe="${coupe}"]`);
				const $seats = _.map(dataCoupe, (el) => $(el).data('number').toString());

				if (_.compact($seats).length === this.carriage.seatsGroupSize) {
					extendNumbers.push(..._.map($seats, (el) => ({ number: el.toString() })));
				}
			} else if ((carPlaceData != null) && (carPlaceData.availableSeats.length === this.carriage.seatsGroupSize)) {
				extendNumbers.push(..._.map(carPlaceData.availableSeats, (el) => ({ number: el.toString() })));
			}
		}

		if (numberIndex >= 0) {
			if (this.numbers.length === 1) {
				this.numbers = [];
			} else if (extendNumbers.length > 0) {
				this.numbers = _.filter(this.numbers, (el) => !_.map(extendNumbers, (n) => n.number).includes(el.number));
			} else {
				this.numbers = [seat];
			}
		} else {
			if (!_.isEmpty(extendNumbers)) {
				this.numbers.push(...extendNumbers);
			} else {
				this.numbers.push(seat);
			}
		}

		const availableStatus = this.updateAvailablePlaces(seat);

		this.parent.selectPlace({
			carriageView: this,
			carriage: this.carriage,
			numbers: this.numbers,
			availableStatus,
		});
	},

	setSelectedGender(value) {
		return this.parent.setSelectedGender(value);
	},

	getSelectedGender() {
		return this.parent.getSelectedGender();
	},

	toggleInmobMapStyles(active) {
		const $svg = this.$el.find('svg');

		// нужно работать со style у каждого отдельного svg
		if ($svg.length) {
			const t = this;
			// eslint-disable-next-line func-names
			$svg.each(function () {
				const $svgEl = $(this);
				const id = $svgEl.attr('id');
				if (id) {
					const $defs = $svgEl.find('defs'); // $style parent
					if (active) {
						const styleContent = t.inmobMapStyleTagContent[id];
						if (styleContent) {
							if (_.size($defs)) {
								$defs.prepend(`<style>${styleContent}</style>`);
							} else {
								$svgEl.prepend(`<defs><style>${styleContent}</style></defs>`);
							}
						}
					} else {
						const $style = $svgEl.find('style');
						if (_.size($style)) {
							t.inmobMapStyleTagContent[id] = $style.html();
							$style.remove();
						}
					}
				}
			});
		}
	},

	convertInmobMapToCompatible() {
		const result = document.createElement('div');
		const $result = $(result);
		const carriage = this.options.carriage;
		const hasCoupe = ['L', 'K', 'P'].includes(carriage.type.uid);

		$result.addClass('b-train__carriage-map');
		$result.append(`<div class="b-train__carriage-map__car-number"><span class="js-car-number"></span></div>`);
		$result.append(this.options.inMobMap);
		const $svg = $result.find('svg'); // <- здесь могут быть несколько svg !!!
		$svg.attr('width', '1200').attr('height', '225');
		$svg.find('#Seats').addClass('inmob-custom-styles');
		$svg.find('#Numbers').addClass('inmob-custom-styles');

		// зачищаем title -> IBECORP-7452
		$svg.find('title').html('');
		
		// проверяем ID у svg и, если его нет, то добавляем
		if ($svg.length) {
			// eslint-disable-next-line func-names
			$svg.each(function () {
				if (this.id == null && window.UUIDGenerator?.generateUUID) {
					this.id = window.UUIDGenerator.generateUUID();
				}
			});
		}

		const completeSVGMap = (svg) => {
			if (svg == null) return;
			const $numbers = $(svg).find('#Numbers').find('text');

			const seatsAmount = _.size($numbers);
			let coupeAmount = 0;
			const groupedSeats = [];

			const appendAttributesToElements = ($numberList, seat, placeData, resultNumbers = []) => {
				const $number = $(_.find($numberList, (number) => parseInt($(number).text(), 10) === parseInt(seat, 10)));
				const number = $number.text();
				const $seat = $result.find(`#Seat${number}`);

				$number
					.attr('data-position', placeData && placeData.carPlaceType && placeData.carPlaceType.uid.toLowerCase())
					.attr('data-number', number);
				$seat
					.attr('data-position', placeData && placeData.carPlaceType && placeData.carPlaceType.uid.toLowerCase())
					.attr('data-number', number);

				if (carriage.type.uid === 'P' && (placeData && placeData.carPlaceType && placeData.carPlaceType.uid.includes('SIDE'))) {
					$number.attr('data-position', placeData.carPlaceType.uid.split('_')[1].toLowerCase());
					$seat.attr('data-position', placeData.carPlaceType.uid.split('_')[1].toLowerCase());

					// eslint-disable-next-line no-unused-vars
					resultNumbers = _.filter(resultNumbers, (n) => $(n).text() !== number);
				}
			};

			if (hasCoupe) {
				if (carriage.type.uid === 'K') {
					coupeAmount = Math.floor(seatsAmount / 4);

					for (let i = 0; i < coupeAmount; i++) {
						groupedSeats.push($numbers.slice(i * 4, (i * 4) + 4));
					}
				}

				if (carriage.type.uid === 'L') {
					coupeAmount = Math.floor(seatsAmount / 2);

					for (let i = 0; i < coupeAmount; i++) {
						groupedSeats.push($numbers.slice(i * 2, (i * 2) + 2));
					}
				}

				let result$numbersForClassP;
				if (carriage.type.uid === 'P') {
					result$numbersForClassP = $numbers;
				}

				_.each(carriage.carPlaceData || carriage.places, (item) => {
					if (item.availableSeats) {
						_.each(item.availableSeats, (s) => {
							appendAttributesToElements($numbers, s, item, result$numbersForClassP);
						});
					} else {
						appendAttributesToElements($numbers, item, null, result$numbersForClassP);
					}
				});

				if (carriage.type.uid === 'P') {
					coupeAmount = Math.floor(result$numbersForClassP.length / 4);
					for (let i = 0; i < coupeAmount; i++) {
						groupedSeats.push(result$numbersForClassP.slice(i * 4, (i * 4) + 4));
					}
				}

				_.each(groupedSeats, (coupe, coupeIndex) => {
					_.each(coupe, (el) => {
						const $el = $(el);
						const number = $(el).text();
						const $seat = $result.find(`#Seat${number}`);

						$el.attr('data-coupe', coupeIndex + 1);
						$seat.attr('data-coupe', coupeIndex + 1);
					});
				});
			} else {
				_.each(carriage.carPlaceData || carriage.places, (item) => {
					if (item.availableSeats) {
						_.each(item.availableSeats, (s) => {
							appendAttributesToElements($numbers, s, item);
						});
					} else {
						appendAttributesToElements($numbers, item, null);
					}
				});
			}
		};

		_.each([$svg[1], $svg[0]], completeSVGMap); // first floor at index 1

		this.templates[this.options.providerCarSchemeId] = _.template($result.prop('outerHTML'));
	},

});
