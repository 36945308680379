// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import PassportModel from '@/blocks/elements/b-passport/model';
import PhoneModel from '@/blocks/pages/b-cabinet/b-cabinet-person/b-cabinet-person-phones/model';
import MailModel from '@/blocks/pages/b-cabinet/b-cabinet-person/b-cabinet-person-emails/model';
import BonusCardModel from '@/blocks/pages/b-cabinet/b-cabinet-person/b-cabinet-person-bonus-card/model';
import RailwaysBonusCardModel from '@/blocks/pages/b-cabinet/b-cabinet-person/b-cabinet-person-bonus-card/b-cabinet-person-bonus-card-railways/model';

export const PassengerModel = BaseModel.extend({

	defaults() {
		return {
			emails: new BaseCollection([], {
				model: MailModel,
			}),
			phones: new BaseCollection([], {
				model: PhoneModel,
			}),
			passports: new BaseCollection([], {
				model: PassportModel,
			}),
			airlineBonusCards: new BaseCollection([], {
				model: BonusCardModel,
			}),
			railwayBonusCards: new BaseCollection([], {
				model: RailwaysBonusCardModel,
			}),
		};
	},

	parse(data) {
		data.emails = new BaseCollection(data.emails, {
			model: MailModel,
		});
		data.phones = new BaseCollection(data.phones, {
			model: PhoneModel,
		});
		data.passports = new BaseCollection(data.passports, {
			model: PassportModel,
		});
		data.airlineBonusCards = new BaseCollection(data.airlineBonusCards, {
			model: BonusCardModel,
		});
		data.railwayBonusCards = new BaseCollection(data.railwayBonusCards, {
			model: RailwaysBonusCardModel,
		});

		return data;
	},

	toJSON(...args) {
		const result = BaseModel.prototype.toJSON.apply(this, args);
		delete result.acceptTwoFactorAuthorization;
		delete result.getAuthToken;

		if (!this.get('getAuthToken') || !this.get('acceptTwoFactorAuthorization')) {
			delete result.googleAuthToken;
			result.securityType = 'DEFAULT';
		} else {
			result.securityType = 'GOOGLE';
		}

		return result;
	},

	handleValidation(...args) {
		if (!this.get('passenger') && args.length > 0 && args[0].passenger != null) {
			args[0] = args[0].passenger;
			if (args[0].passports != null) {
				const passports = args[0].passports;
				Object.keys(passports).forEach(key => {
					const passport = passports[key];
					if (passport.middleName != null) {
						args[0].middleName = passport.middleName;
					}
				});
			}
		}
		BaseModel.prototype.handleValidation.apply(this, args);
	},

});

export default PassengerModel;
